import React from 'react';
import { ExtensionLoginButton, LedgerLoginButton, WalletConnectLoginButton, WebWalletLoginButton } from '@multiversx/sdk-dapp/UI';
import { getIsLoggedIn } from '@multiversx/sdk-dapp/utils';
import { routeNames } from 'routes';
import { ReactComponent as DefiWallet } from '../../assets/icon/defi-wallet.svg';
import ledgerIcon from '../../assets/icon/ledger-square-white.png';
import { ReactComponent as MaiarIcon } from '../../assets/icon/maiar.svg';
import { IoMdCloseCircle } from 'react-icons/io';
import './Login.scss';

export function UnlockRoute(props: any) {

  React.useEffect(() => {
    const isLoggedIn = getIsLoggedIn();
    if (isLoggedIn) {
      window.location.href = routeNames.staking;
    }
  }, []);

  return (
    <div className="login-container">
      <IoMdCloseCircle
        id="login-close"
        onClick={() => {
          props.closeModal();
        }}
      />
      <div className="login-button-title">connect a wallet</div>
      <div className="login-button-container">
        <DefiWallet className="unlock-icon" />
        <ExtensionLoginButton
          loginButtonText={"DeFi Wallet"}
          buttonClassName="btn-connect"
        >
          <p className="login-button-text">DeFi Wallet</p>
        </ExtensionLoginButton>
      </div>
      <div className="login-button-container">
        <MaiarIcon className="unlock-icon" />
        <WalletConnectLoginButton
          logoutRoute="/"
          modalClassName="maiar-modal"
          title="open xportal"
          lead="scan using xportal"
          buttonClassName="btn-connect"
          innerWalletConnectComponentsClasses={{
            containerContentClassName: "wc-container-content",
            containerLegacyClassName: "wc-container-legacy",
            containerButtonClassName: "wc-container-button",
            containerTitleClassName: "wc-container-title",
          }}
          showScamPhishingAlert={false}
          isWalletConnectV2={true}
        >
          <p className="login-button-text">xPortal</p>
        </WalletConnectLoginButton>
      </div>
      <div className="login-button-container">
        <MaiarIcon className="unlock-icon" />
        <WebWalletLoginButton
          callbackRoute={routeNames.staking}
          loginButtonText={"Web Wallet"}
          buttonClassName="btn-connect"
        >
          <p className="login-button-text">Web Wallet</p>
        </WebWalletLoginButton>
      </div>
      <div className="login-button-container">
        <img src={ledgerIcon} className="unlock-icon" id="unlock-icon_ledger" />
        <LedgerLoginButton
          loginButtonText={"Ledger"}
          callbackRoute={routeNames.staking}
          modalClassName=""
          buttonClassName="btn-connect"
        >
          <p className="login-button-text">Ledger</p>
        </LedgerLoginButton>
      </div>
    </div>
  );
};

export default UnlockRoute;
