import React, { useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { FaDiscord } from 'react-icons/fa';
import { FaTwitterSquare } from 'react-icons/fa';
import { RiCloseFill } from 'react-icons/ri';
import images from '../../constants/images';
import './Navbar.scss';

function NavbarHeader(props: any) {
    return (
        <div className="navbar-header">
            <div className="navbar-header_logo">
                <img src={images.iconLogoWhite} alt="drifters logo" />
            </div>
            <div className="navbar-header_title">
                {props.name}
            </div>
            {props.toggleMenu ?
                <RiCloseFill color="white" fontSize={27} onClick={() => { console.log('there'); props.setToggleMenu(!props.toggleMenu) }} /> :
                <GiHamburgerMenu fontSize={27} onClick={() => { console.log('here'); props.setToggleMenu(!props.toggleMenu) }} />
            }
        </div>
    )
}

export default NavbarHeader
