import * as React from "react";
import { useState, useEffect, CSSProperties } from 'react';
import { useGetAccountInfo, useTrackTransactionStatus, } from "@multiversx/sdk-dapp/hooks";
import { getIsLoggedIn, logout } from "@multiversx/sdk-dapp/utils";
import * as toHex from "to-hex";
import UnlockRoute from "pages/UnlockPage";
import Modal from "react-modal";
import { innovatorCollectionId, proxy, voyagerColletionId as voyagerCollectionId } from "config";
import { getNftsByAddressAndByCollections, getNftsByNonces, getTokenHolderCount, getAddressTokens, getAccountNftCount, getTokenHolders } from "api/elrond";
import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers/out";
import { useSmartContract } from "util/smartcontract";
import Cockpit from "../../components/Cockpit/Cockpit";
import { DefaultSeat, defaultSeat, Ship, shipDefault } from "app/types";
import queries from "interactions/queries";
import { url } from "inspector";
import { motion } from 'framer-motion'
import PuffLoader from "react-spinners/PuffLoader";
import './Index.scss';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const Staking = () => {
  let proxyNetworkProvider = new ProxyNetworkProvider(proxy);
  const contract = useSmartContract();
  const { address } = useGetAccountInfo();
  const isLoggedIn = getIsLoggedIn();

  const [fleetName, setFleetName] = React.useState<string>('');
  const [currentSeason, setCurrentSeason] = useState<number>(0);
  const [loginModalIsOpen, setLoginModalIsOpen] = React.useState(false);
  const [crewShipArray, setCrewShipArray] = useState<Array<Ship>>([]);
  const [crewshipArrayWithSeats, setCrewshipArrayWithSeats] = useState<Array<Ship>>([]);
  const [crewShipRewards, setCrewshipRewards] = React.useState<Array<Ship>>([]);

  const [totalRewards, setTotalRewards] = useState(0);
  const [totalShips, setTotalShips] = useState(0);

  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false);
  const [transactionSessionId, setTransactionSessionId] = React.useState<string | null>(null);
  const [nftList, setNftList] = React.useState<Array<any> | undefined>([]);

  const [loadingShip, setLoadingShip] = useState(false);

  const dkmId = 'DKM-592719';
  const [dkmHolders, setDkmHolders] = useState<{ address: string, balance: number, fleetName: string }[]>([]);
  const [userDkmRank, setUserDkmRank] = useState(0);
  const [tokenHolderCount, setTokenHolderCount] = useState(0);

  const [userInnovatorsStaked, setUserInnovatorStaked] = useState(0);
  const [userVoyagersStaked, setUserVoyagersStaked] = useState(0);

  const loungeBackgroundUrl = "./assets/background/lounge.png";
  const loungeMobileBackgroundUrl = "./assets/background/loungeMobile.png";
  const [backgroundUrl, setBackgroundUrl] = useState(loungeBackgroundUrl);

  const disconnect = () => {
    logout(`${window.location.origin}`);
  };
  const openLoginModal = () => {
    setLoginModalIsOpen(true);
  };
  const closeModal = () => {
    setLoginModalIsOpen(false);
  };

  function IsScreenVertical() {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const isVertical = screenWidth < screenHeight;
    return isVertical;
  }

  useEffect(() => {
    if (IsScreenVertical()){
      setBackgroundUrl(loungeMobileBackgroundUrl)
    }
  }, []);

  // Called when smart contract is ready
  useEffect(() => {
    // setIsLoadingModalOpen(isLoggedIn);
    const getFleetName = async () => {
      if (isLoggedIn && contract !== "") {
        let fleetName = await queries.getFleetNameForAddress(contract, proxyNetworkProvider, address);
        setFleetName(fleetName);
      }
    }
    const getShips = async () => {
      if (isLoggedIn && contract !== "") {
        setCurrentSeason(
          await queries.getCurrentSeason(contract, proxyNetworkProvider)
        );
        const crewShips = await queries.getShipByAddress(contract, proxyNetworkProvider, address);

        setCrewShipArray(crewShips);

        if (crewShips.length === 0) {
          setIsLoadingModalOpen(false);
        }
      }
    };

    getTokenHolders(dkmId, 50, 1).then((result) => {
      async function fetchData() {
        if (result.data.length > 0) {
          for (const dkmHolderRetrieved of result.data) {
            const fleetName = await queries.getFleetNameForAddress(contract, proxyNetworkProvider, dkmHolderRetrieved.address);
            const convertedBalance = Math.trunc(dkmHolderRetrieved.balance / (10 ** 18));
            setDkmHolders((dkmHolders) => [...dkmHolders, { address: dkmHolderRetrieved.address, balance: convertedBalance, fleetName: fleetName ? fleetName : ''}]);
          }
        }
      }
      fetchData().catch((error) => console.log(error));
    });


    getTokenHolders(dkmId, 5000, 1).then((result) => {
      const fetchData = async () => {
        if (typeof result.data !== "undefined" && result.data.length > 0) {
          // find the address we want in the list of objects
          const foundAddress = result.data.find((obj: any) => obj.address === address);

          setUserDkmRank(result.data.indexOf(foundAddress) + 1);
        }
      };
      fetchData().catch((error) => console.log(error));
    });


    getShips().catch((error) => console.log(error));
    getFleetName().catch((error) => console.log(error));
    // eslint-disable-next-line
  }, [contract]);

  useEffect(() => {
    const getTotalShips = async () => {
      setTotalShips(await queries.getActiveCrewship(contract, proxyNetworkProvider, currentSeason));
    }
    getTotalShips().catch((e) => console.log(e));
  }, [currentSeason]);

  async function populateCrewshipArraySeats() {
    for (let i = 0; i < crewShipArray.length; i++) {
      if (crewShipArray[i].seatsFetched === false) {
        const nftsInCrewship = await queries.getNftsInCrewship(contract, proxyNetworkProvider, crewShipArray[i].id);

        let nonces = nftsInCrewship[0].map((e: number) => `${voyagerCollectionId}-${toHex(e, { evenLength: true })}`);
        nonces = nonces.concat(nftsInCrewship[1].map((e: number) => `${innovatorCollectionId}-${toHex(e, { evenLength: true })}`));

        setUserInnovatorStaked(prevUserInnovatorsStaked => prevUserInnovatorsStaked + nftsInCrewship[1].length);
        setUserVoyagersStaked(prevUserVoyagersStaked => prevUserVoyagersStaked + nftsInCrewship[0].length);

        const drifters = await getNftsByNonces(nonces);
        const driftersWithThumbnails = drifters.data.map((e: any) => {
          e.url = e.media && e.media[0] && e.media[0].thumbnailUrl ? e.media[0].thumbnailUrl : e.url;
          return e;
        });

        crewShipArray[i].seats = driftersWithThumbnails as DefaultSeat[];
        crewShipArray[i].seatsFetched = true;
      };
    }
    setCrewshipArrayWithSeats([...crewShipArray]);
  };

  async function populateCrewshipArrayRewards() {
    for (const crewship of crewShipArray) {
      if (crewship.rewardsFetched === false) {
        // console.log(`calc reward for ${JSON.stringify(crewship)}`);
        const reward = await queries.calculateRewardsForCrewship(contract, proxyNetworkProvider, crewship.id);

        const updatedCrewship = { ...crewship, reward, rewardsFetched: true };

        // Replace the current object in the array with updated object
        const index = crewShipArray.indexOf(crewship);
        crewShipArray[index] = updatedCrewship;

        // setCrewShipArray(prevCrewShipArray => {
        //   const index = prevCrewShipArray.indexOf(crewship);
        //   const newCrewShipArray = [...prevCrewShipArray];
        //   newCrewShipArray[index] = updatedCrewship;
        //   return newCrewShipArray;
        // });

        setTotalRewards(prevCrewShipReward => prevCrewShipReward + reward);
      }
    }
    // use the spread operator when updating state in React, to avoid modifying the state in place and causing unexpected behavior. 
    setCrewshipRewards([...crewShipArray]);
  };

  useEffect(() => {
    populateCrewshipArraySeats();
    populateCrewshipArrayRewards();
  }, [crewShipArray])

  useEffect(() => {
    if (isLoggedIn) {
      getNftsByAddressAndByCollections(address, [
        voyagerCollectionId,
        innovatorCollectionId,
      ])
        .then((res) => {
          const formatNfts = res.data.map((nft: any) => {
            nft.url =
              nft.media && nft.media[0] && nft.media[0].thumbnailUrl
                ? nft.media[0].thumbnailUrl
                : nft.url;
            return nft;
          });
          setNftList(formatNfts);
        })
        .catch((error) => setNftList(undefined));
    }
  }, [isLoggedIn, address]); // added address because of eslint

  useEffect(() => {
    getTokenHolderCount(dkmId).then((result) => {
      setTokenHolderCount(result.data);
    });
  }, [])

  return (
    <>
      <motion.img
        key={backgroundUrl}
        src={backgroundUrl}
        initial={{ zIndex: -1, opacity: 0, width: "100%", height: "100%", position: "fixed" }}
        animate={{ opacity: .5, transition: { duration: 1 } }}
        exit={{ opacity: 0 }}>
      </motion.img>
      <Modal
        isOpen={loginModalIsOpen}
        onRequestClose={closeModal}
        className="login-modal"
      >
        <UnlockRoute
          closeModal={closeModal}
        // setIsLoadingModalOpen={setIsLoadingModalOpen}
        ></UnlockRoute>
      </Modal>
      <Modal
        className="modal__page-loader"
        isOpen={isLoadingModalOpen}
      >
        {/* <div className="modal__page-loader-loader">
          <PuffLoader color={color} loading={true} size={100} />
        </div> */}
      </Modal>
      {isLoggedIn ? (
        <>
          <Cockpit
            setTransactionSessionId={setTransactionSessionId}
            currentSeason={currentSeason}
            nftList={nftList}
            loadingShip={loadingShip}
            crewShipArray={crewShipArray}
            disconnect={disconnect}
            fleetName={fleetName}
            address={address}
            contract={contract}
            totalRewards={totalRewards}
            totalShips={totalShips}
            dkmHolders={dkmHolders}
            userDkmRank={userDkmRank}
            crewShipRewards={crewShipRewards}
            crewshipArrayWithSeats={crewshipArrayWithSeats}
            userInnovatorsStaked={userInnovatorsStaked}
            userVoyagersStaked={userVoyagersStaked}
            tokenHolderCount={tokenHolderCount}
          />
        </>
      ) : (
        <>
          <div>
            <div className="landing__body">
              <div className='landing__title'>the lounge</div>
              <div className='landing__button' onClick={openLoginModal}>login</div>
              <img src="./assets/iconLogoWhite.svg" className='landing__logo' />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Staking;
