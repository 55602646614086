import React, { useState } from 'react';
import './Navbar.scss';
import NavbarHeader from './NavbarHeader';
import images from '../../constants/images';

function Navbar(props: any) {
    return (
        <div>
            <div className="navbar">
                <div className="navbar-header_logo">
                    <img src={images.iconLogoWhite} alt="drifters logo" />
                </div>
                <div className="navbar-address">
                    {props.address}
                </div>
                <div className="navbarItem" onClick={props.disconnect}>
                    logout
                </div>
            </div>

            <div
                className="navbar-smallscreen"
                id="navbar-smallscreen">
                <NavbarHeader
                    name={''}
                    setToggleMenu={props.setIsMenuModalOpen}
                />
            </div>
        </div >
    )
}

export default Navbar
