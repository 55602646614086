import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers/out";
import { List, ResultsParser, SmartContract } from "@multiversx/sdk-core/out";
import { defaultSeat, Ship } from "app/types";

const getCurrentSeason = async (
  contract: SmartContract,
  networkProvider: ProxyNetworkProvider
) => {
  const interaction = contract.methods.getCurrentSeason();
  const query = interaction.check().buildQuery();

  const queryResponse = await networkProvider.queryContract(query);
  const endpointDefinition = interaction.getEndpoint();

  const { firstValue } = new ResultsParser().parseQueryResponse(
    queryResponse,
    endpointDefinition
  );

  return firstValue?.valueOf().toNumber();
};

const isRegistered = async (
  contract: SmartContract,
  networkProvider: ProxyNetworkProvider,
  address: any
) => {
  const interaction = contract.methods.isRegistered([address]);
  const query = interaction.check().buildQuery();

  const queryResponse = await networkProvider.queryContract(query);
  const endpointDefinition = interaction.getEndpoint();

  const { firstValue } = new ResultsParser().parseQueryResponse(
    queryResponse,
    endpointDefinition
  );

  return firstValue?.valueOf();
};

const isValidateName = async (
  contract: SmartContract,
  networkProvider: ProxyNetworkProvider,
  fleetName: any
) => {
  const interaction = contract.methods.isValidateName([fleetName]);
  const query = interaction.check().buildQuery();

  const queryResponse = await networkProvider.queryContract(query);
  const endpointDefinition = interaction.getEndpoint();

  const { firstValue } = new ResultsParser().parseQueryResponse(
    queryResponse,
    endpointDefinition
  );

  return firstValue?.valueOf();
};

const isFleetNameAvailable = async (
  contract: SmartContract,
  networkProvider: ProxyNetworkProvider,
  fleetName: any
) => {
  const interaction = contract.methods.isFleetNameAvailable([fleetName]);
  const query = interaction.check().buildQuery();

  const queryResponse = await networkProvider.queryContract(query);
  const endpointDefinition = interaction.getEndpoint();

  const { firstValue } = new ResultsParser().parseQueryResponse(
    queryResponse,
    endpointDefinition
  );

  return firstValue?.valueOf();
};

const getFleetNameForAddress = async (
  contract: SmartContract,
  networkProvider: ProxyNetworkProvider,
  address: any
): Promise<any> => {
  const interaction = contract.methods.getFleetNameForAddress([address]);
  const query = interaction.check().buildQuery();

  const queryResponse = await networkProvider.queryContract(query);
  const endpointDefinition = interaction.getEndpoint();

  const { firstValue } = new ResultsParser().parseQueryResponse(
    queryResponse,
    endpointDefinition
  );

  return firstValue?.valueOf().toString();
};

const getShipByAddress = async (
  contract: SmartContract,
  networkProvider: ProxyNetworkProvider,
  address: any
): Promise<any> => {
  const interaction = contract.methods.getCrewshipsForAddress([address]);
  const query = interaction.check().buildQuery();

  const queryResponse = await networkProvider.queryContract(query);
  const endpointDefinition = interaction.getEndpoint();

  const { firstValue } = new ResultsParser().parseQueryResponse(
    queryResponse,
    endpointDefinition
  );

  const data = firstValue?.valueOf().map((e: any) => {
    const ship: Ship = {
      crew_size: e.crew_size.toNumber(),
      epoch: e.epoch.toNumber(),
      id: e.id.toNumber(),
      name: e.name.toString(),
      season: e.season.toNumber(),
      status: e.status.name,
      seats: new Array(5).fill(defaultSeat),
      seatsFetched: false,
      reward:0,
      rewardsFetched: false,
    };
    return ship;
  });

  return data;
};

const getNftsInCrewship = async (
  contract: SmartContract,
  networkProvider: ProxyNetworkProvider,
  crewShipId: any
) => {
  const interaction = contract.methods.getNftsInCrewship([crewShipId]);
  const query = interaction.check().buildQuery();

  const queryResponse = await networkProvider.queryContract(query);
  const endpointDefinition = interaction.getEndpoint();

  const { firstValue } = new ResultsParser().parseQueryResponse(
    queryResponse,
    endpointDefinition
  );

  const firstValueAsStruct = firstValue as List;
  const nonces = firstValueAsStruct
    .getItems()
    .map((e: any) => e.valueOf().map((e: any) => e.toNumber()));
  return nonces;
};

const calculateRewardsForCrewship = async (contract: SmartContract, networkProvider: ProxyNetworkProvider, crewShipId: number) => {
  const interaction = contract.methods.calculateRewardsForCrewship([
    crewShipId,
  ]);
  const query = interaction.check().buildQuery();

  const queryResponse = await networkProvider.queryContract(query);
  const endpointDefinition = interaction.getEndpoint();

  const { firstValue } = new ResultsParser().parseQueryResponse(
    queryResponse,
    endpointDefinition
  );

  return firstValue?.valueOf().toNumber() / (Math.pow(10, 18));
};

const getActiveCrewship = async (contract: SmartContract, networkProvider: ProxyNetworkProvider, season: number) => {
  const interaction = contract.methods.getActiveCrewship([season]);
  const query = interaction.check().buildQuery();

  const queryResponse = await networkProvider.queryContract(query);
  const endpointDefinition = interaction.getEndpoint();

  const { firstValue } = new ResultsParser().parseQueryResponse(
    queryResponse,
    endpointDefinition
  );

  return firstValue?.valueOf().toNumber();
};

const queries = {
  getShipByAddress,
  isRegistered,
  isFleetNameAvailable,
  isValidateName,
  getFleetNameForAddress,
  getNftsInCrewship,
  getCurrentSeason,
  calculateRewardsForCrewship,
  getActiveCrewship
};

export default queries;
