import React from "react";
import {
  SignTransactionsModals,
  NotificationModal,
  TransactionsToastList,
} from "@multiversx/sdk-dapp/UI";
import { DappProvider } from "@multiversx/sdk-dapp/wrappers";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import Layout from "components/Layout";
import PageNotFound from "pages/PageNotFound";
import routes from "routes";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const environment = "mainnet";

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const App = () => {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Router>      <DappProvider
        environment={environment}
        customNetworkConfig={{
          name: "drifterConfig",
          walletConnectV2ProjectId: "6634f5c2a1110d55f5fa097a6498b74f",
        }}
      >
          <Layout>
            <NotificationModal />
            <SignTransactionsModals className="drifters-sign-tx" />
            <TransactionsToastList />
            <Routes>
              {routes.map((route: any, index: number) => (
                <Route
                  path={route.path}
                  key={"route-key-" + index}
                  element={<route.component />}
                />
              ))}
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Layout>
        </DappProvider>
      </Router>
    </ThemeProvider>
  );
};

export default App;
