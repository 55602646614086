import axios from 'axios';
import { api } from 'config';

const getNftsByAddressAndByCollections = async (address: string, collections: Array<string>) => {
  return axios.get(`${api}/accounts/${address}/nfts?collections=${collections.join('%2C')}&size=1000`);
};

const getNftsByNonces = (tokenIds: Array<string>) => {
  return axios.get(`${api}/nfts?identifiers=${tokenIds.join(',')}&size=1000`);
};

const getAddressTokens = async (address: string, tokenId: string,) => {
  return axios.get(`${api}/accounts/${address}/tokens?identifier=${tokenId}`);
};

const getAccountNftCount = async (address: string, collection: string) => {
  return axios.get(`${api}/accounts/${address}/nfts/count?collection=${collection}`);
}

const getTokenHolders = async (tokenId: string, size: number, from: number) => {
  return axios.get(`${api}/tokens/${tokenId}/accounts?from=${from}&size=${size}`);
}

const getTokenHolderCount = async (tokenId: string) => {
  return axios.get(`${api}/tokens/${tokenId}/accounts/count`);
}
export {
  getNftsByAddressAndByCollections,
  getNftsByNonces,
  getAddressTokens,
  getAccountNftCount,
  getTokenHolders,
  getTokenHolderCount
};
