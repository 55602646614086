import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export default function StakedLinearProgress(props: any) {
    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        setProgress(props.percentage)
    }, [props]);

    return (
        <div className='stakedLinearProgress'>
            <LinearProgress sx={{ height: '10px' }} variant="determinate" value={progress} color={props.color} />
            <div className='stakedProgress-header'>
                {props.label}
            </div>
            <div className='stakedProgress-info'>
                {props.count}
            </div>
        </div>
    );
}