import { useState, useEffect } from 'react';
import { AbiRegistry, SmartContractAbi } from '@multiversx/sdk-core';
import abiFile from './abi/stakingv2.abi.json';
import { AbiType } from 'app/types';

export const useABI = () => {
    const [abi, setAbi] = useState<SmartContractAbi | undefined>(undefined);

    useEffect(() => {
        async function readABIFile() {
            const abiRegistry = AbiRegistry.create(abiFile as unknown as AbiType);
            return abiRegistry;
        }

        readABIFile()
            .then((abiRegistry) =>
                setAbi(new SmartContractAbi(abiRegistry, ['staking'])),
            )
            .catch((err) => console.log(err));
    }, []);

    return abi;
};