import { BooleanValue } from "@multiversx/sdk-core/out";

export type AbiType = {
	name: string;
	endpoints: any[];
	types: any[];
};

export interface DefaultSeat {
  url: string;
  name: string;
  identifier: string;
  collection: string;
  nonce: number;
  attributes: Attributes | null;
}

export interface Attributes {
  trait_type: string;
  value: string;
}

export const defaultSeat: DefaultSeat = {
  url: "",
  name: "",
  identifier: "",
  collection: "",
  nonce: 0,
  attributes: null,
};

export interface Ship {
  id: number;
  name: string;
  season: number;
  epoch: number;
  crew_size: number;
  status: string;
  seats: DefaultSeat[];
  seatsFetched: boolean;
  reward: number;
  rewardsFetched: boolean;
}

export const shipDefault = () : Ship => {
  return {
    id: 0,
    name: '',
    season: 0,
    epoch: 0,
    crew_size: 0,
    status: "None",
    seats: new Array(5).fill(defaultSeat),
    seatsFetched: false,
    reward: 0,
    rewardsFetched: false,
  }
};