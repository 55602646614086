import React, { useEffect, useRef, useState } from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

export default function DataTable(props: any) {
    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Rank', width: 70 },
        { field: 'fleet', headerName: 'Leaderboard', width: 200 },
        { field: 'balance', headerName: 'Balance', type: 'number', width: 70 },
        { field: 'percentage', headerName: 'Percentage', width: 130}
    ];

    const [rows, setRows] = useState<{rank:number, fleet:string, balance:number, address:string, percentage:string}[]>([]);

    useEffect(() => {
        let id = 1;
        const newData = props.dkmHolders
        .filter((item:any) => item.fleetName !== "ARMADA" && item.fleetName !== "")
        .map((item:any) => {
          return {
            id: id++,
            fleet: item.fleetName,
            balance: item.balance,
            percentage: `${(item.balance / props.dkmCirculatingSupply * 100).toFixed(4)} %`
          };
        });
        setRows(newData);
    }, [props.dkmHolders])

    return (
        <div className="cockpit__table" style={{ height: 400 }}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
            />
        </div>
    );
}