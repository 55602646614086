import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { api, chainID, innovatorCollectionId, proxy, voyagerColletionId } from 'config';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { defaultSeat, Ship, DefaultSeat } from '../../app/types';
import { Address, SmartContract, TokenPayment } from '@multiversx/sdk-core/out';
import { refreshAccount } from '@multiversx/sdk-dapp/utils/account';
import { useSmartContract } from 'util/smartcontract';
import { getIsLoggedIn } from '@multiversx/sdk-dapp/utils';
import queries from '../../interactions/queries';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import DrifterSelectPanel from './DrifterSelectPanel';
import './Cockpit.scss';
import Footer from './Footer';
import { motion } from 'framer-motion';
import Navbar from 'components/Navbar/Navbar';
import NavbarHeader from 'components/Navbar/NavbarHeader';
import { getNftsByAddressAndByCollections, getNftsByNonces, getAddressTokens, getAccountNftCount, getTokenHolders } from "api/elrond";
import DkmTable from './DkmTable';
import ShipsTable from './ShipsTable';
import StakedCircularProgress from './StakedCircularProgress';
import StakedLinearProgress from './StakedLinearProgress';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

const Cockpit = (props: any) => {
  const { address } = useGetAccountInfo();
  const stakingAddress = 'erd1qqqqqqqqqqqqqpgq684slltwj7at6ylurjfsmvwy3j748qy9kdjs4m742l';
  const isLoggedIn = getIsLoggedIn();
  const sc: SmartContract = useSmartContract();
  let proxyNetworkProvider = new ProxyNetworkProvider(proxy);

  const dkmId = 'DKM-592719';

  const [accountVoyagers, setAccountVoyagers] = useState([]);
  const [accountInnovators, setAccountInnovators] = useState([]);
  const [accountRajis, setAccountRajis] = useState([]);

  const [innovatorsStaked, setInnovatorsStaked] = useState(0);
  const [voyagersStaked, setVoyagersStaked] = useState(0);

  //dkm
  const [accountDkm, setAccountDkm] = useState(0);
  const [dkmCirculatingSupply, setDkmCirculatingSupply] = useState(0);

  // season counter
  const seasonEndDates = ["",
    "November 17, 2022",
    "December 15, 2022",
    "January 12, 2023",
    "Feburary 9, 2023",
    "March 9, 2023",
    "April 6, 2023",
    "May 4, 2023",
    "June 1, 2023",
    "June 29, 2023",
    "July 27, 2023",
    "August 24, 2023",
    "September 21, 2023",
  "October 19, 2023",
  "November 16, 2023",
  "December 14, 2023"]
  const [daysLeft, setDaysLeft] = useState(0);
  const [phase, setPhase] = useState("");


  const [isMenuModalOpen, setIsMenuModalOpen] = useState(false);

  useEffect(() => {
    getAccountNftCount(stakingAddress, innovatorCollectionId).then((result) => {
      setInnovatorsStaked(result.data);
    });
    getAccountNftCount(stakingAddress, voyagerColletionId).then((result) => {
      setVoyagersStaked(result.data);
    });
  }, [])

  useEffect(() => {
    let season = props.currentSeason;
    let targetDate = new Date(seasonEndDates[season - 1]);
    const currentDate = new Date();

    const differenceInTime = targetDate.getTime() - currentDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);
    const daysUntil = Math.round(differenceInDays);
    setDaysLeft(daysUntil);

    if (daysUntil >= 21) {
      setPhase("Preparation");
      setDaysLeft(daysUntil - 21);
    } else {
      setPhase("Exploration");
    }
  }, [props.currentSeason])

  useEffect(() => {
    {
      getNftsByAddressAndByCollections(address,
        [voyagerColletionId, innovatorCollectionId]).then((result) => {
          const formatNfts = result.data.map((nft: any) => {
            nft.url =
              nft.media && nft.media[0] && nft.media[0].thumbnailUrl
                ? nft.media[0].thumbnailUrl
                : nft.url;
            return nft;
          });
          const innovators = formatNfts.filter((nft: any) => nft.collection == innovatorCollectionId);
          const voyagers = formatNfts.filter((nft: any) => nft.collection == voyagerColletionId);
          setAccountVoyagers(voyagers);
          setAccountInnovators(innovators);
        });

      getAddressTokens(address, dkmId).then((result) => {
        if (result.data.length > 0) {
          setAccountDkm(Math.trunc(result.data[0].balance / 10 ** 18));
        }
      })

      getTokenHolders(dkmId, 1, 0).then((result) => {
        if (result.data.length > 0) {
          setDkmCirculatingSupply(Math.round(20000000 - (result.data[0].balance / (10 ** 18))));
        }
      })
    }
  }, [address])

  return (
    <div>
      {isLoggedIn && (
        <div>
          <Navbar
            disconnect={props.disconnect}
            setIsMenuModalOpen={setIsMenuModalOpen}
            address={props.address}
          />
          <div className='cockpit__container'>
          <div className="cockpit__cards">
            <div className='cockpit__header-cards'>
              <div className='cockpit__card'>
              <div className='cockpit__card-header'>
                  VAULT
                </div>
                <div className='cockpit__card-info'>
                  Balance: {accountDkm} DKM
                </div>
                <div className='cockpit__card-header'>
                  CREW
                </div>
                <div className='cockpit__card-info'>
                  Innovators: {accountInnovators.length + props.userInnovatorsStaked}
                </div>
                <div className='cockpit__card-info'>
                  Voyagers: {accountVoyagers.length + props.userVoyagersStaked}
                </div>

                <div className='cockpit__card-header'>
                  DARK MATTER
                </div>
                <div className='cockpit__card-info'>
                  Circulating Supply: {dkmCirculatingSupply} ({(dkmCirculatingSupply / 20000000 * 100).toFixed(2)}%)
                </div>
                <div className='cockpit__card-info'>
                  Total Supply: 20.000.000
                </div>
                <div className='cockpit__card-info'>
                  Holders: {props.tokenHolderCount}<br />
                </div>
                <div className='cockpit__card-info'>
                  Rank: {props.userDkmRank}<br />
                </div>

                <div className='cockpit__card-header'>
                  Explorers
                </div>
                <div className='cockpit__card-info'>
                  Season: {props.currentSeason}
                </div>
                <div className='cockpit__card-info'>
                  Phase: {phase}
                </div>
                <div className='cockpit__card-info'>
                  Days Left: {daysLeft}
                </div>
                <div className='cockpit__card-info'>
                  Ships: {props.totalShips}
                </div>

                <div className='cockpit__card-header'>
                  Staked
                </div>
                <div className='cockpit__card-info'>
                  innovators: {innovatorsStaked} / 1000
                  <div id='stakedLinearProgress'><StakedLinearProgress
                    percentage={Math.trunc((innovatorsStaked / 1000) * 100)}
                    color='primary'
                  />
                  </div>
                </div>
                <div className='cockpit__card-info'>
                  voyagers: {voyagersStaked} / 10000
                  <div id='stakedLinearProgress'><StakedLinearProgress
                    percentage={Math.trunc((voyagersStaked / 10000) * 100)}
                    color='secondary'
                  />
                  </div>
                </div>

                <div className='cockpit__card-header'>
                  {props.fleetName}
                </div>
                <div className='cockpit__card-info'>
                  Ships: {props.crewShipArray.length}
                </div>
                <div className='cockpit__card-info'>
                  S{props.currentSeason} Harvest: {Math.trunc(props.totalRewards)} DKM
                </div>
              </div>
            </div>
            <div className='cockpit__tables'>
              <DkmTable
                dkmHolders={props.dkmHolders}
                dkmCirculatingSupply={dkmCirculatingSupply}
              />
            </div>
          </div>
          </div>
          <Modal
            className="modal"
            isOpen={isMenuModalOpen}
            ariaHideApp={false}
          >
            <NavbarHeader setToggleMenu={setIsMenuModalOpen} toggleMenu={true} name={""} />
            <div className="modal-body">
              <div className="navbar-smallscreen_links">
                <a href="https://drifters.app/home">
                  <div>Home</div>
                </a>
                <div onClick={() => {
                  setIsMenuModalOpen(false);
                  props.disconnect();
                }}>
                  Logout
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )
      }
    </div >
  );
};

export default Cockpit;
